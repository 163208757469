import http from "../http-common";

export const getAllOrders = (page,per_page,invoice,accepted,created_by)=> {
    return http.get(`/order?sort=-id&include=items.material,createdBy,purchasedBy&pagination=true&perPage=${per_page}&page=${page}${invoice}${accepted}${created_by}`);
}

export const createOrders = (data)=> {
    return http.post(`/order`,data);
}

export const deleteOrders = (data)=> {
    return http.delete(`/order/${data}`);
}

export const getOrder = (id)=> {
    return http.get(`/order/${id}?include=items.material`);
}

export const updateOrders = (id,items)=> {
    return http.put(`/order/${id}`,items);
}

export const deleteItem = (order_id,material_id)=> {
    return http.delete(`/order/${order_id}/item/${material_id}`);
}